// Colors

//   primary colors
// $primary: #15049e;
$primary: #ff5900;
$secondary: #affcc2;
$tertiary: #786cd0;
$neutral: #f1f1f1;
$quaternary: #f0eefc;

$white: #fff;
$black: #000;
$mirage: #151724;
$ebony-clay: #252737;
$tuna: #303241;
$bright-gray: #353849;
$abby: #424258;
$trout: #484a59;
$alto: #dadada;
$silver-chalice: #ababab;
$spun-pearl: #ababb7;
$athens-grey: #e7e7ed;
$whisper: #f2f2f8;

$persimmon: #ff584e;
$burnt-sienna: #eb5953;
$flush-orange: #fb7800;
$vivid-tangerine: #ff8883;
$razzle-dazzle-rose: #ff38c7;
$lightning1: #ffbc17;
$lightning1-light: rgba(255, 188, 23, 0.25);
$lightning2-light: rgba(255, 234, 45, 0.35);
$lightning2: #ffea2d;
$bright-sun: #ffcc3d;
$sunglow: #ffe42a;
$candlelight: #ffd523;
$cerulean: #009ee1;
$dodger-blue: #00b2ff;
$dodger-blue-2: #2867f4;
$malibu: #7ad7ff;
$reef: #c8ffaf;
$java: #23aac1;
$bubblegum: #35d3ed;
$jade: #00a863;
$steel-gray: #282b3a;
$jumbo: #7e7e85;
$lime: #6dcc30;
$dark-red: #a10202;

$lightning-yellow: linear-gradient(140.67deg, $lightning1 12.93%, $lightning2 77.48%);

$lightning-yellow-hover: linear-gradient(140.67deg, $lightning2 12.93%, $lightning1 77.48%);

// Semantic colors
$nav-text: $spun-pearl;
$disabled-text: $spun-pearl;
$add-link-text: $spun-pearl;
$login-error-text: $spun-pearl;
$delete-modal-text: $spun-pearl;
$dark-text: $mirage;
$link-text: $dodger-blue;
$toggle-label-text: $spun-pearl;
$toggle-button-bg: $mirage;
$toggle-button-selected-text: $mirage;

$border: $bright-gray;
$focus-border: $malibu;
$thumbnail-border: $abby;
$icon-border-color: $abby;
$avatar-file-upload-border: $abby;
$tertiary-button-border: $spun-pearl;
$new-formation-button-border: $trout;

$select-arrow: $spun-pearl;
$select-focus-bg: $abby;

$card-bg: $tuna;
$signup-bg: $bright-gray;
$play-card-bg: $bright-gray;
$roster-bg: $bright-gray;
$roster-bg-hover: $trout;
$play-settings-bg: $bright-gray;
$input-bg: $mirage;
$dark-bg: $mirage;
$modal-overlay-bg: linear-gradient(0deg, rgba(64, 63, 76, 0.9), rgba(64, 63, 76, 0.9));
$modal-bg: $tuna;
$toast-bg: $whisper;
$play-editor-bg: $whisper;
$input-disabled-bg: $trout;

$logo-file-upload-bg: $tuna;

$success-toast: $jade;
$error-toast: $burnt-sienna;
$info-toast: $dodger-blue;

$loader-light: $abby;

$selected-text-color: $whisper;
$selected-bg: $bright-gray;
$filter-option-bg: $cerulean;

$dark-player-detail-text: $mirage;
$rank-text: $bright-gray;
$player-modal-background: $athens-grey;
$ranking-chart-bg: $lightning-yellow;
$header-position-text: $spun-pearl;
$readiness-bg: $trout;
$add-players-text: $spun-pearl;
$draft-mode-button-text: $jumbo;
$draft-mode-text: $spun-pearl;
$draft-mode-hover: $lightning1-light radial-gradient(circle, transparent 0%, $lightning1-light 1%)
  center/15000%;
$draft-mode-selected: $lightning2-light;
$draft-mode-bg: $steel-gray;

$gamePlan-select-btn: $cerulean;
$plays-selected-text: $mirage;
$add-to-gamePlan-btn-bg: $steel-gray;
$create-gamePlan-subtext: $spun-pearl;
$license-suspended-subtext: $spun-pearl;
