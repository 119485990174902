@import '../../styles/variables';

.customer-portal {
  .container {
    .left-container {
      background: url(../../assets/images/customer-portal-img.png) no-repeat;
      background-position: -65px 80%;
      background-size: contain;
    }
  }
}

// .customer-portal .container {
//     max-width: 1900px !important;
// }
// .customer-portal .customer-portal-contnt .customer-portal-left{
// 	background-color: #15049E;
// 	padding: 83px;
// 	position: relative;
// }

// .customer-portal .customer-portal-contnt{
// 	display: flex;
// 	justify-content: center;
// 	align-items: flex-start;
// }

// .customer-portal .customer-portal-contnt .customer-portal-left,
// .customer-portal .customer-portal-contnt .customer-portal-right{
// 	width: 50%;
// 	min-height: 780px;
// 	margin-bottom: 55px;
// 	margin-top: 55px;

// }

// .customer-portal .customer-portal-contnt .customer-portal-right{
// 	padding: 220px 135px;
// }

// .customer-portal .customer-portal-contnt .customer-portal-left{
// 	text-align: center;
// 	overflow: hidden;
// }

// .customer-portal .customer-portal-contnt .customer-portal-left .customer-portal-logo{
// 	max-width: 180px;
// }

// .customer-portal .customer-portal-contnt .customer-portal-left h2{
// 	color: #F0EEFC;
// 	text-align: center;
// 	font-size: 48px;
// 	font-style: normal;
// 	font-weight: 400;
// 	line-height: 54px;
// 	margin-top: 90px;
// }

// .customer-portal .customer-portal-contnt .customer-portal-left .customer-portal-img{
// 	position: absolute;
//     max-width: 625px;
//     left: -80px;
//     bottom: 45px;
//     height: 290px;
// }

// .customer-portal .customer-portal-contnt .customer-portal-right h3{
// 	color: #15049E;
// 	font-size: 32px;
// 	font-style: normal;
// 	font-weight: 400;
// 	line-height: 40px;

// }

// .customer-portal .customer-portal-contnt .customer-portal-right p{
// 	color:#15049E;
// 	font-size: 16px;
// 	font-style: normal;
// 	font-weight: 400;
// 	line-height: 24px;
// 	margin-bottom: 40px;
// }

// .customer-portal .customer-portal-contnt .customer-portal-right .form-group {
//     margin-bottom: 40px;
// }

// .customer-portal .customer-portal-contnt .customer-portal-right .form-control{
// 	border: none;
// 	padding-left: 0;
// 	border-radius: 0;
// 	border-bottom:2px solid #F0EEFC;
// 	height: 50px;
// 	color: #15049E;
// 	font-size: 22px;
// 	font-style: normal;
// 	font-weight: 400;
// 	line-height: 28px;
// }

// .customer-portal .customer-portal-contnt .customer-portal-right .form-control::placeholder {
// 	color: #D0CDEC;
// 	font-size: 22px;
// 	font-style: normal;
// 	font-weight: 400;
// 	line-height: 28px;
// }

// .customer-portal .customer-portal-contnt .customer-portal-right .form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
// 	color: #D0CDEC;
// 	font-size: 22px;
// 	font-style: normal;
// 	font-weight: 400;
// 	line-height: 28px;
// }

// .customer-portal .customer-portal-contnt .customer-portal-right .form-control::-ms-input-placeholder { /* Microsoft Edge */
// 	color: #D0CDEC;
// 	font-size: 22px;
// 	font-style: normal;
// 	font-weight: 400;
// 	line-height: 28px;
// }

// .customer-portal .customer-portal-contnt .customer-portal-right .btn-warning{
// 	color: #15049E;
// 	text-align: center;
// 	font-size: 18px;
// 	font-style: normal;
// 	font-weight: 700;
// 	line-height: 16px;
// 	border-radius: 4px;
// 	background-color: #F1D972 !important;
// 	width: 207px;
// 	height: 50px;
// 	border: none;
// }

// .customer-portal .customer-portal-contnt .customer-portal-right .btn-warning:hover{
// 	background-color: #D3B01A !important;
// }

// .custom-checkbox{
// 	margin-bottom: 40px;
// 	position: relative;
// }

// .custom-checkbox .checkbox-container {
// 	color: #15049E;
// 	font-size: 14px;
// 	font-style: normal;
// 	font-weight: 400;
// 	line-height: 18px;
// 	letter-spacing: 0.1px;
// 	margin-left: 14px;
// 	max-width: 380px;
// }

// .custom-checkbox .checkbox-container input {
//   position: absolute;
//   opacity: 0;
//   cursor: pointer;
//   height: 0;
//   width: 0;
// }

// .custom-checkbox .checkmark {
// 	position: absolute;
// 	top: 0;
// 	left: 0;
// 	height: 25px;
// 	width: 25px;
// 	background-color: #fff;
// 	border: 2px solid #15049E;
// 	border-radius: 4px;
// }

// .custom-checkbox .checkbox-container:hover input ~ .checkmark {
//   background-color: #ccc;
// }

// .custom-checkbox .checkbox-container input:checked ~ .checkmark {
//   background-color: #15049E;
//   border-radius: 4px;
// }

// .custom-checkbox .checkmark:after {
//   content: "";
//   position: absolute;
//   display: none;
// }

// .custom-checkbox .checkbox-container input:checked ~ .checkmark:after {
//   display: block;
// }

// .custom-checkbox .checkbox-container .checkmark:after {
// 	left: 8px;
// 	top: 4px;
// 	width: 5px;
// 	height: 11px;
// 	border: solid white;
// 	border-width: 0 2px 2px 0px;
// 	-webkit-transform: rotate(45deg);
// 	-ms-transform: rotate(45deg);
// 	transform: rotate(45deg);
// }

// .customer-portal .customer-portal-contnt .customer-portal-right p.resend-otp{
// 	color: #15049E;
// 	font-size: 16px;
// 	font-style: normal;
// 	font-weight: 700;
// 	line-height: 20px;
// 	margin: 45px 0 0 0;
// }

// .customer-portal .customer-portal-contnt .customer-portal-right p.resend-otp span{
// 	text-decoration-line: underline;
// }

// @media(max-width: 1200px){
// 	.customer-portal .customer-portal-contnt .customer-portal-left h2{
// 	    font-size: 36px;
// 	    line-height: 44px;
// 	}
// 	.customer-portal .customer-portal-contnt .customer-portal-right {
// 	    padding: 220px 90px;
// 	}

// 	.customer-portal .customer-portal-contnt .customer-portal-right h3{
// 		font-size: 30px;
// 	}

// 	.customer-portal .customer-portal-contnt .customer-portal-left .customer-portal-img {
// 	    max-width: 100%;
// 	    height: auto;
// 	}

// 	.customer-portal .customer-portal-contnt .customer-portal-right {
// 	    padding: 220px 55px;
// 	}
// }

// @media(max-width: 992px){
// 	.customer-portal .customer-portal-contnt .customer-portal-left h2 br{
// 		display: none;
// 	}

// 	.customer-portal .customer-portal-contnt .customer-portal-left {
// 	    padding: 40px 20px 70px 20px;
// 	}

// 	.customer-portal .customer-portal-contnt .customer-portal-left,
// 	.customer-portal .customer-portal-contnt .customer-portal-right{
// 		min-height: 700px;
// 	}

// 	.customer-portal .customer-portal-contnt .customer-portal-left h2 {
// 	    font-size: 34px !important;
// 	    line-height: 38px !important;
// 	}

// 	.customer-portal .customer-portal-contnt .customer-portal-left .customer-portal-img{
// 		left: -45px;
// 	}

// 	.customer-portal .customer-portal-contnt .customer-portal-right {
// 	    padding: 185px 35px 0 35px;
// 	}

// 	.customer-portal .customer-portal-contnt .customer-portal-right h3 {
// 	    font-size: 26px;
// 	}

// 	.customer-portal .customer-portal-contnt .customer-portal-right p{
// 		margin-bottom: 24px;
// 	}

// 	.customer-portal .customer-portal-contnt .customer-portal-right .btn-warning{
// 		margin-top: 30px;
// 	}
// }

// @media(max-width: 767px){
// 	.customer-portal .container{
// 	    padding: 0;
// 	}

// 	.customer-portal .customer-portal-contnt .customer-portal-left h2{
// 		margin-top: 45px;
// 	}

// 	.customer-portal .customer-portal-contnt{
// 		flex-direction: column;
// 	}

// 	.customer-portal .customer-portal-contnt .customer-portal-left,
// 	.customer-portal .customer-portal-contnt .customer-portal-right{
// 		width: 100%;
// 		margin: 0;
// 		min-height: auto;
// 	}

// 	.customer-portal .customer-portal-contnt .customer-portal-left .customer-portal-img{
// 		position: initial;
// 		width: 70%;
// 		margin: 50px auto 0 auto;
// 	}

// 	.customer-portal .customer-portal-contnt .customer-portal-left,
// 	.customer-portal .customer-portal-contnt .customer-portal-right{
// 		padding:45px;
// 	}
// }

// @media(max-width: 576px){

// 	.customer-portal .customer-portal-contnt .customer-portal-left .customer-portal-logo {
// 	    max-width: 105px;
// 	}

// 	.customer-portal .customer-portal-contnt .customer-portal-left h2,
// 	.customer-portal .customer-portal-contnt .customer-portal-right h3{
// 		font-size: 28px !important;
// 	}
// 	.customer-portal .customer-portal-contnt .customer-portal-right p {
// 	    margin-bottom: 45px;
// 	    font-size: 14px;
// 	}

// 	.customer-portal .customer-portal-contnt .customer-portal-right .form-group {
// 	    margin-bottom: 30px;
// 	}

// 	.customer-portal .customer-portal-contnt .customer-portal-right .btn-warning{
// 		width: 100%;
// 	}

// 	.customer-portal .customer-portal-contnt .customer-portal-right{
// 		padding-bottom: 130px;
// 	}

// 	.customer-portal .customer-portal-contnt .customer-portal-left .customer-portal-img{
// 		width: 100%;
// 	}

// 	.customer-portal .customer-portal-contnt .customer-portal-right p.resend-otp{
// 		text-align: center;
// 		font-size: 12px;
// 		line-height: 16px;
// 		margin: 18px 0 0 0;
// 	}
// }

input[type='checkbox'] {
  accent-color: $primary;
  border-color: $primary;
  //   outline: 2px solid #c00;
  // border: 1px solid red;
  border: 2px solid $primary !important;

  &.focus\:ring-2:focus {
    box-shadow: none !important;
  }
}
