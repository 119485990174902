/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@tailwind base;
@tailwind components;
@tailwind utilities;

.react-tel-input .form-control {
  @apply !w-full py-6 !text-base;
}

.opt-container {
  @apply flex justify-between gap-2;
}
.opt-container input {
  @apply !mr-0 flex-1 py-5 outline-none;
}

/* @layer base {
  html {
    font-family: Proxima Nova, system-ui, sans-serif;
  }
} */

/* @layer base {
  @font-face {
    font-family: ProximaNova;
    font-weight: 400;
    src: url(./assets/fonts/proximanova-semibold.ttf)format("ttf");
  }
  @font-face {
    font-family: ProximaNova;
    font-weight: 500;
    src: url(./assets/fonts/proximanova-semibold-2.ttf) format("ttf");
  }
}

body {
  font-family: 'ProximaNova', sans-serif;
} */

/* @font-face {
  font-family: 'Proxima Nova Regular';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/proximanova-regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Proxima Nova Bold';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/proximanova-bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Proxima Nova Semibold';
  font-style: normal;
  font-weight: 600;
  src: url('./assets/fonts/proximanova-semibold.ttf') format('truetype');
}

@font-face {
  font-family: 'Soehne';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/soehne-breit-kraftig.ttf') format('truetype');
} */

/* @font-face {
  font-family: 'Aeonik Regular';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/daylight-fonts/Aeonik-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Aeonik Bold';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/daylight-fonts/Aeonik-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Aeonik Light';
  font-style: normal;
  font-weight: 600;
  src: url('./assets/fonts/daylight-fonts/Aeonik-Light.otf') format('opentype');
} */

@font-face {
  font-family: "FormaDJRText Light";
  src: url("./assets/fonts/daylight-fonts/FormaDJRBanner-Bold.woff2")
      format("woff2"),
    url("./assets/fonts/daylight-fonts/FormaDJRBanner-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "FormaDJRText Light";
  src: url("./assets/fonts/daylight-fonts/FormaDJRBanner-Bold.woff2")
      format("woff2"),
    url("./assets/fonts/daylight-fonts/FormaDJRBanner-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "FormaDJRText Light";
  src: url("./assets/fonts/daylight-fonts/FormaDJRText-Light.woff2")
      format("woff2"),
    url("./assets/fonts/daylight-fonts/FormaDJRText-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "FormaDJRText Bold";
  src: url("./assets/fonts/daylight-fonts/FormaDJRText-Bold.woff2")
      format("woff2"),
    url("./assets/fonts/daylight-fonts/FormaDJRText-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "FormaDJRText Regular";
  src: url("./assets/fonts/daylight-fonts/FormaDJRText-Regular.woff2")
      format("woff2"),
    url("./assets/fonts/daylight-fonts/FormaDJRText-Regular.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "FormaDJRMono Regular";
  src: url("./assets/fonts/daylight-fonts/FormaDJRMono-Regular.woff2")
      format("woff2"),
    url("./assets/fonts/daylight-fonts/FormaDJRMono-Regular.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "FormaDJRMono Regular";
  src: url("./assets/fonts/daylight-fonts/FormaDJRMono-Regular.woff2")
      format("woff2"),
    url("./assets/fonts/daylight-fonts/FormaDJRMono-Regular.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "FormaDJRBanner Regular";
  src: url("./assets/fonts/daylight-fonts/FormaDJRBanner-Regular.woff2")
      format("woff2"),
    url("./assets/fonts/daylight-fonts/FormaDJRBanner-Regular.woff")
      format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "FormaDJRBanner Medium";
  src: url("./assets/fonts/daylight-fonts/FormaDJRBanner-Medium.woff2")
      format("woff2"),
    url("./assets/fonts/daylight-fonts/FormaDJRBanner-Medium.woff")
      format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "FormaDJRUI Regular";
  src: url("./assets/fonts/daylight-fonts/FormaDJRUI-Regular.woff2")
      format("woff2"),
    url("./assets/fonts/daylight-fonts/FormaDJRUI-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "FormaDJRDeck Light";
  src: url("./assets/fonts/daylight-fonts/FormaDJRDeck-Light.woff2")
      format("woff2"),
    url("./assets/fonts/daylight-fonts/FormaDJRDeck-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "FormaDJRDeck Regular";
  src: url("./assets/fonts/daylight-fonts/FormaDJRDeck-Regular.woff2")
      format("woff2"),
    url("./assets/fonts/daylight-fonts/FormaDJRDeck-Regular.woff")
      format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "FormaDJRDeck Medium";
  src: url("./assets/fonts/daylight-fonts/FormaDJRDeck-Medium.woff2")
      format("woff2"),
    url("./assets/fonts/daylight-fonts/FormaDJRDeck-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@layer base {
  html {
    font-family: FormaDJRBanner Regular, FormaDJRText Regular, sans-serif;
    font-feature-settings: "ss11" on;
  }
}

@media (max-width: 1024px) {
  .mainContentsection {
    flex-direction: column !important;
  }

  .mainContentsection .mainContentleft,
  .mainContentsection .mainContentRight {
    width: 100% !important;
  }

  .mainContentsection .mainContentRight {
    padding-top: 50px;
  }
}
